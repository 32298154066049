import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { useServicesPageHeadingSection } from "../hooks/ServicesPage/useServicesPageHeadingSection"
import { useServicesPageServiceContentSection } from "../hooks/ServicesPage/useServicesPageServiceContent"
import Loadable from "@loadable/component"
import ClipLoader from "react-spinners/ClipLoader";
import Layout from "../components/Layout/Layout"
import PageHeaderSection from "../components/PageHeaderSection/PageHeaderSection"
import PageSubHeaderSection from "../components/PageSubHeaderSection/PageSubHeaderSection"
const withSpinner = (importComponent) => Loadable(importComponent, {
  fallback: <ClipLoader color="#2e41ff" size={150} cssOverride={{ display: 'block', margin: '50px auto', borderWidth: '4px' }} aria-label="Loading Spinner" />,
});
const SeoPage = Loadable(() => import("../components/seo-page"))
const PageServiceSection = withSpinner(() => import("../components/PageServiceSection/PageServiceSection"));
const PageImageSection = withSpinner(() => import("../components/PageImageSection/PageImageSection"));
const ServicePointsOfInterest = withSpinner(() => import("../components/ServicePointsOfInterest/ServicePointsOfInterest"));
const FaqSection = withSpinner(() => import("../components/FaqSection/FaqSection"));
const PartnersSection = withSpinner(() => import("../components/PartnersSection/PartnersSection"));
const Footer = withSpinner(() => import("../components/Footer/Footer"));

const ServicesPage = () => {
  const { title, subtitle, headingSectionContent } =
    useServicesPageHeadingSection()
  const { serviceContent } = useServicesPageServiceContentSection()

  const { content } = serviceContent[0]
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    keyword: {
      en: "Ensys",
      ro: "Ensys",
    },
    slug: {
      en: "Soluții complete de energie verde",
      ro: "Soluții complete de energie verde",
    },
  }

  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }
  return (
    <Layout>
      <SeoPage keyword={getSeoFields("keyword")} slug={getSeoFields("slug")} />
      <PageHeaderSection
        title={title}
        subtitle={subtitle}
        headingSectionContent={headingSectionContent}
      />
      <PageSubHeaderSection img={content.image} />

      {serviceContent.length &&
        serviceContent.map((service, index) => {
          const {
            image,
            icon: { localFile },
            title: serviceTitle,
            programText,
            topContent,
            bottomContent,
            buttonLink,
          } = service.content
          return (
            <>
              {index !== 0 && (
                <PageImageSection position={image.imagePosition} img={image} />
              )}
              <PageServiceSection
                icon={localFile.url}
                link={buttonLink}
                title={serviceTitle}
                contactText={programText}
                content1={topContent}
                content2={bottomContent}
              />
            </>
          )
        })}
      <ServicePointsOfInterest />
      <FaqSection />
      <PartnersSection />
      <Footer />
    </Layout>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default ServicesPage
