import React from "react"
import PropTypes from "prop-types"
import "./PageSubHeaderSection.scss"

const PageSubHeaderSection = ({
  img: {
    imageSource: { localFile },
  },
}) => {
  return (
    <div className="pageSubHeaderSection container">
      <div className="row">
        {localFile && (
          <div
            className="page-image col-12 col-xl-8 "
            style={{ backgroundImage: `url('${localFile.url}')` }}
          />
        )}
      </div>
    </div>
  )
}

PageSubHeaderSection.propTypes = {
  img: PropTypes.shape({
    imageSource: PropTypes.shape({
      localFile: PropTypes.shape({ url: PropTypes.string }),
    }),
  }).isRequired,
}

export default PageSubHeaderSection
