import React from "react"
import PropTypes from "prop-types"
import Header from "../Header/Header"
import "./PageHeaderSection.scss"

const PageHeaderSection = ({
  title,
  subtitle,
  headingSectionContent: {
    content,
    icon: { localFile },
  },
}) => {
  return (
    <div className="pageHeaderSection">
      <Header className="header-over" />
      <div className="container ">
        <div className="row">
          <div className="content-container col-12 col-xl-8">
            {title && <h1>{title}</h1>}
            {subtitle && <h2>{subtitle}</h2>}
          </div>
          <div className="content-container-withIcon col-12 col-xl-4">
            {localFile && (
              <img className="section-icon" alt="icon" src={localFile.url} />
            )}
            {content && (
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PageHeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  headingSectionContent: PropTypes.shape({
    content: PropTypes.string,
    icon: PropTypes.shape({
      localFile: PropTypes.shape({ url: PropTypes.string }),
    }),
  }).isRequired,
}

export default PageHeaderSection
