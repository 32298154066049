import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useServicesPageServiceContentSection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Services" } }
        language: { code: { eq: RO } }
      ) {
        servicesPageFields {
          servicesPageContent {
            serviceContent {
              content {
                icon {
                  localFile {
                    url
                  }
                }
                title
                programText
                image {
                  imagePosition
                  imageSource {
                    localFile {
                      url
                    }
                  }
                }
                topContent
                bottomContent
                buttonLink {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Services" } }
        language: { code: { eq: EN } }
      ) {
        servicesPageFields {
          servicesPageContent {
            serviceContent {
              content {
                icon {
                  localFile {
                    url
                  }
                }
                title
                programText
                image {
                  imagePosition
                  imageSource {
                    localFile {
                      url
                    }
                  }
                }
                topContent
                bottomContent
                buttonLink {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    pages[language]?.servicesPageFields?.servicesPageContent ||
    wpPageRo?.servicesPageFields?.servicesPageContent
  )
}
