import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useServicesPageHeadingSection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Services" } }
        language: { code: { eq: RO } }
      ) {
        servicesPageFields {
          servicesPageHeadingSection {
            title
            subtitle
            headingSectionContent {
              content
              icon {
                localFile {
                  url
                }
              }
            }
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Services" } }
        language: { code: { eq: EN } }
      ) {
        servicesPageFields {
          servicesPageHeadingSection {
            title
            subtitle
            headingSectionContent {
              content
              icon {
                localFile {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    pages[language]?.servicesPageFields?.servicesPageHeadingSection ||
    wpPageRo?.servicesPageFields?.servicesPageHeadingSection
  )
}
